import { v4 } from "uuid";

export const INFO_ABOUT_PASSING_TEST = [
  {
    id: v4(),
    key: "duration_in_minutes",
    icon: "schedule",
    test: "хвилин",
  },
  {
    id: v4(),
    key: "question_limit_per_exam",
    icon: "format_list_bulleted",
    test: "питань",
  },
  {
    id: v4(),
    key: "min_right_answers",
    icon: "verified",
    test: "правильних відповідей для успішного проходження",
  },
];
