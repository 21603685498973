import { v4 } from "uuid";

export const EXAMS_TABLE_CONFIG = [
  {
    id: v4(),
    name: "Дата початку",
    key: "created_at",
  },
  {
    id: v4(),
    name: "Дата завершення",
    key: "finishedAtPreview",
  },
  {
    id: v4(),
    name: "Версія",
    key: "version",
  },
  {
    id: v4(),
    name: "Статус",
    key: "status",
  },
  {
    id: v4(),
    name: "",
    key: "id",
  },
];
