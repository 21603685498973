













































































































































































import { Component, Vue, Emit } from "vue-property-decorator";
import Modal from "@/components/UI/Modal.vue";
import CheckboxField from "@/components/form/CheckboxField.vue";
import { namespace } from "vuex-class";
import { USER_PROCESSING_EXAM } from "@/constants/storeNames";
import { app_routes } from "@/router/app_routes";

const processingExam = namespace(USER_PROCESSING_EXAM);

@Component({
  components: {
    Modal,
    CheckboxField,
  },
})
export default class RulesModal extends Vue {
  public consentToProcessingOfData = false;

  @processingExam.Action startExam!: () => Promise<number | null>;

  async getStartExam(): Promise<void> {
    const examId: number | null = await this.startExam();

    if (examId) {
      await this.$router.push(`${app_routes.exam_processing.slug}/${examId}`);
    }
  }

  @Emit()
  public close(): void {
    return;
  }
}
