






































































































































































































































import Component, { mixins } from "vue-class-component";
import { namespace } from "vuex-class";
import { QuestionKitsMixin } from "@/mixins/QuestionKitsMixin";
import Table from "@/components/table/Table.vue";
import MaterialIcon from "@/components/UI/MaterialIcon.vue";
import Loader from "@/components/UI/Loader.vue";
import SwitchContent from "@/components/UI/SwitchContent.vue";
import Timer from "@/components/UI/Timer.vue";
import ParamInfo from "@/components/UI/ParamInfo.vue";
import SignExamDataModal from "@/components/Eds/SignExamDataModal.vue";
import ExamsStatusBar from "@/components/ExamsStatusBar.vue";
import RulesModal from "@/components/RulesModal.vue";
import { INFO_ABOUT_PASSING_TEST } from "@/config/infoAboutPassingTest";
import { EXAM_STATUS_CONFIG } from "@/config/examStatusConfig";
import { EXAMS_TABLE_CONFIG } from "@/config/examsTableConfig";
import { app_routes } from "@/router/app_routes";
import { ExamType } from "@/types/API/ExamType";
import { USER_POPULAR_QUESTIONS_CONFIG } from "@/config/userPopularQuestionsConfig";
import { UserInfoType } from "@/types/API/UserInfoType";
import { DateFormatPreview } from "@/services/DateFormat/DateFormatPreview";
import { MILISECONDS_IN_MINUTE, TIME_EXIT } from "@/constants/timeConstants";
import { EXAM_DONE_STATUSES } from "@/config/examDoneStatuses";
import { SignInfoType, SignResultType } from "@/types/API/SignInfoType";
import {
  USER_EXAMS_HISTORY,
  USER_MANAGER_MODULE,
  USER_PROCESSING_EXAM,
} from "@/constants/storeNames";
import {
  STATUS_IN_PROGRESS,
  STATUS_PENDING,
  STATUS_SUCCESS,
} from "@/constants/testStatuses";

const processingExam = namespace(USER_PROCESSING_EXAM);
const userExams = namespace(USER_EXAMS_HISTORY);
const userManager = namespace(USER_MANAGER_MODULE);
const OVERFLOW_HIDDEN = "hidden";
const OVERFLOW_AUTO = "auto";

@Component({
  filters: {
    formatDate(date: string): string {
      return DateFormatPreview.getFullDate(date);
    },
  },
  components: {
    Timer,
    Loader,
    ParamInfo,
    RulesModal,
    MaterialIcon,
    SwitchContent,
    ExamsStatusBar,
    SignExamDataModal,
    "base-table": Table,
  },
})
export default class UserHome extends mixins(QuestionKitsMixin) {
  public readonly popularQuestionsConfig = USER_POPULAR_QUESTIONS_CONFIG;
  public readonly infoAboutPassingTest = INFO_ABOUT_PASSING_TEST;
  public readonly examStatusConfig = EXAM_STATUS_CONFIG;
  public readonly examsTableConfig = EXAMS_TABLE_CONFIG;
  public readonly pathToExamProcessingPage = app_routes.exam_processing.slug;
  public readonly pathSlugToExamResultPage = app_routes.exam_result.slug;
  public showEdsModal = false;
  public showRulesModal = false;

  @userExams.State examsList!: ExamType[];
  @userExams.State loading!: boolean;
  @userManager.State userInfo!: UserInfoType;

  /**
   * The check to status of the current exam is the progress status
   */
  get hasProgressExamStatus(): boolean {
    return this.hasProgressStatus(this.currentExam?.status);
  }

  /**
   * The check to status of the current exam is the pending status
   */
  get hasPendingExamStatus(): boolean {
    return this.hasPendingStatus(this.currentExam?.status);
  }

  /**
   * The check to status of the current exam is the success status
   */
  get hasSuccessExamStatus(): boolean {
    return this.currentExam?.status === STATUS_SUCCESS;
  }

  /**
   * The check to status of the current exam is the done status
   */
  get hasDoneStatus(): boolean {
    return EXAM_DONE_STATUSES.includes(this.currentExam?.status || "");
  }

  /**
   * Last passed exam
   */
  get currentExam(): ExamType | undefined {
    const FIRST_INDEX = 0;
    return this.examsList[FIRST_INDEX];
  }

  /**
   * Resolution time of next test
   */
  get timeNextTesting(): Date {
    const examExitTime = new Date(this.currentExam?.finished_at || 0).getTime();
    const pendingTime =
      parseInt(this.testSettings.pending_after_fail_in_minutes || "0") *
      MILISECONDS_IN_MINUTE;

    return new Date(examExitTime + pendingTime);
  }

  /**
   * Waiting after a failed attempt
   */
  get hasBlockedExamProcessing(): boolean {
    const nowTime = new Date().getTime();
    const timeLeft = this.timeNextTesting.getTime() - nowTime;
    const hasTimeExited = timeLeft > TIME_EXIT;

    // update the list of exams when the time is up
    if (hasTimeExited) {
      setTimeout(async () => {
        await this.getExamsList();
      }, timeLeft);
    }

    return this.hasDoneStatus && this.hasSettings && hasTimeExited;
  }

  @userExams.Action getExamsList!: () => Promise<void>;
  @userManager.Action getUserInfo!: () => Promise<void>;
  @userManager.Action userUpdateEmail!: (email: string | null) => Promise<void>;
  @processingExam.Action sendSign!: (params: {
    id: number;
    sign: string;
  }) => Promise<SignResultType>;
  @processingExam.Action getExamSignInfo!: (
    id: number
  ) => Promise<SignInfoType>;

  async created(): Promise<void> {
    await this.getExamsList();
  }

  destroyed(): void {
    this.setBodyOverflow(OVERFLOW_AUTO);
  }

  /**
   * Check status is progress
   * @param {string|undefined} status
   */
  hasProgressStatus(status?: string): boolean {
    return status === STATUS_IN_PROGRESS;
  }

  /**
   * Check status is pending
   */
  hasPendingStatus(status?: string): boolean {
    return status === STATUS_PENDING;
  }

  /**
   * Toggle visible rules modal
   */
  toggleVisibleRulesModal(show: boolean): void {
    this.showRulesModal = show;
    this.setBodyOverflow(show ? OVERFLOW_HIDDEN : OVERFLOW_AUTO);
  }

  /**
   * Set the overflow style to the page
   * @param {'hidden'|'auto'} param
   */
  setBodyOverflow(param: string): void {
    document.body.style.overflow = param;
  }
}
